.fill-white { fill: #000000; }
.print-header { border-bottom: 1px solid #000000; }
#top__nav .nav { display: none; }
#footer { border-top: 1px solid #000000; }
.footer__inner__column { display: none; }

body, input, textarea { font-size: 12px; }
h1 { font-size: 20px; margin-bottom: 1rem; }
h2 { font-size: 18px; }
h2, h3 { margin-top: 1rem; }
p, li { line-height: 1.5rem; }
li { margin-bottom: 0; }
pre, code, th, td { font-size: 12px; }
pre { padding: 0; }